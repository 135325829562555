$(document).ready(function () {

    /* Carosusel home LO SAPEVI mobile */
    if ($('.ruota-mobile__carousel').length) {
        $('.ruota-mobile__carousel').slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            arrows: false,
            fade: false,
            adaptiveHeight: true
        });
    }

    /* Carosusel home LO SAPEVI desktop */
    if ($('.sapevi__carousel').length) {
        $('.sapevi__carousel').slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            arrows: false,
            fade: false,
            adaptiveHeight: false
        });
    }
});