/*
 * script.js
 */
function numberWithCommas(n) {
    let parts = n.toString().split(".");
    return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".") + (parts[1] ? "," + parts[1] : "");
}

$(document).ready(function () {
    //"use strict"; //JSHINT
    var $menuMobile; //JSHINT

    /* Funzione per il menu responsive */
    $menuMobile = $('.open-mobile-nav');
    if ($menuMobile.length) {
        $menuMobile.bigSlide({
            'menu': '#mobile-nav',
            'push': '.push-by-mobile-nav',
            'side': 'right',
            'menuWidth': '260px',
            'speed': '600',
            'easyClose': true
        });
    }


    var $carrello = $('#cart-bigSlide-open');
    /*
    if ($carrello.length) {
        $carrello.bigSlide({
            'menu': '#carrelloSidebar',
            'push': '.wrap',
            'side': 'right',
            'speed': '600',
            'easyClose': true
        });
    }
    */

    // Dichiarazione tooltip bootstrap
    $(function () {
        $('[data-toggle="tooltip"]').tooltip();
    });
    // Dichiarazione popover bootstrap
    $(function () {
        $('[data-toggle="popover"]').popover()
    });
    // Dichiarazione carousel bootstrap (modale)
    $(function () {
        $('.carousel').carousel({
            wrap: false
        })
    });

    $('#fissa_appuntamento').click(function () {
        $('.mappa').addClass('mappa--active');
        $('.quotazione').removeClass('quotazione--active');
    });

    $('#salva_quotazione').click(function () {
        $('.quotazione').addClass('quotazione--active');
        $('.mappa').removeClass('mappa--active');
    });

    $('#salva_quotazione_mobile').click(function () {
        $('.quotazione').addClass('quotazione--active');
        $('.mappa').removeClass('mappa--active');
    });

    $('#fissa_appuntamento_mobile').click(function () {
        $('.quotazione').removeClass('quotazione--active');
        $('.mappa').addClass('mappa--active');
    });

    $('#garancies_btn_open').click(function () {
        $('.sidebar__mobile__garancies').toggleClass('sidebar__mobile__garancies--active');
    });

    $('#garancies_btn_close').click(function () {
        $('.sidebar__mobile__garancies').toggleClass('sidebar__mobile__garancies--active');
    });

    // GaugeMeter nella Sidebar
    var GaugeMetere = $(".GaugeMeter");
    if (GaugeMetere.length) {
        GaugeMetere.gaugeMeter();
    }

    // SmoothScroll
    $('.smooth-scroll').click(function () {
        $('html, body').animate({scrollTop: $($(this).attr('href')).offset().top}, 500);
        return false;
    });

    /* Funzione per il cambio icona a destra dell'accordion */
    $('.collapse').on('shown.bs.collapse', function (e) {
        $(this).prev('.row').find('.fa-chevron-up').removeClass('fa-chevron-up').addClass('fa-chevron-down');
        $(this).prev('.panel-heading').find('.fa-plus').removeClass('fa-plus').addClass('fa-minus');
        e.stopPropagation();
    }).on('hidden.bs.collapse', function (e) {
        $(this).prev('.row').find('.fa-chevron-down').removeClass('fa-chevron-down').addClass('fa-chevron-up');
        $(this).prev('.panel-heading').find('.fa-minus').removeClass('fa-minus').addClass('fa-plus');
        e.stopPropagation();
    });

    /* Accordion delay si chiude dopo 10 sec */
    // $( "#dati1 i.more-less" ).removeClass("fa-plus").addClass("fa-minus");
    // $(function () {
    //     setTimeout(function () {
    //         $("#collapsedati1").collapse({
    //             toggle: true
    //         });
    //     }, 5000);
    // });

    /* Accordion dati si chiude allo scroll */
    $( "#dati1 i.more-less" ).removeClass("fa-plus").addClass("fa-minus");

    $(window).scroll(function () {
        $("#collapsedati1").collapse({toggle: true}, ($(window).scrollTop() > 100));
    });

    /* Funzione per il cambio bottone Salva < > Modifica nell'accordion */
    $('#accordion-edit-btn').click(function () {
        $('#accordion-edit').toggleClass('accordion-content--active');
        $('#accordion-save').toggleClass('accordion-content--active');
    });

    $('#accordion-save-btn').click(function () {
        $('#accordion-edit').toggleClass('accordion-content--active');
        $('#accordion-save').toggleClass('accordion-content--active');
    });


    /* Funzione per cambiare accordion da select in pagina FAQ */
    if ($('.faq__tabs').length) {
        //$("#tabNav").select2();
        $("#tabNav").change(function () {
            $('.faq__tabs__navigator a[href="#' + $(this).val() + '"]').tab('show')
        });
    }

    /* Funzione per Range Slider in Modale */
    if ($('main').hasClass('wrapper')) {
        if ($('#rangeInput1').length) {
            $('#rangeInput1').slider({
                handle: 'custom',
                formatter: function (value) {
                    return numberWithCommas(value) + ' €';
                }
            }).on('slide', function (slideEvt) {
                $('#rangeNumber1').text(numberWithCommas(slideEvt.value) + ' €');
            });
        }
        if ($('#rangeInput2').length) {
            $('#rangeInput2').slider({
                handle: 'custom',
                formatter: function (value) {
                    return numberWithCommas(value) + ' €';
                }
            }).on('slide', function (slideEvt) {
                $('#rangeNumber2').text(numberWithCommas(slideEvt.value) + ' €');
            });
        }
    }


    if ($('#navigatore').length) {
        var cursore = 1;
        var strCursore;
        var limitCursore = $('[id^="scroller_anchor_"]').length;

        $("#navigatore").click(function () {
            if (cursore > limitCursore) {
                cursore = 1;
                $("#navigatore").removeClass('navigatore--top');
                $('html, body').animate({scrollTop: 0}, 500);
            }
            else {
                strCursore = '#scroller_anchor_' + cursore.toString();
                $('html, body').animate({scrollTop: $(strCursore).offset().top}, 500);
                if (cursore == limitCursore) {
                    $("#navigatore").addClass('navigatore--top');
                }
                cursore++;
            }
        });
    }

    var $premioError = $('.premio__error');
    if ($premioError.length)
        var sticky = new Waypoint.Sticky({
            element: $premioError[0]
        });

    /* apre carrello sidebar */

    $toggleCarrelloOpen = $('#toggleCarrelloOpen');
    $('#carrelloSidebar').show();
    $toggleCarrelloOpen.hide().removeClass("container-btn-carrello-two--open");


    // APERTURA CARRELLO
    $('#toggleCarrello').click(function () {
        $('#carrelloSidebar').animate({
            right: "315px"
        },600).toggleClass("carrello_open");
        $(this).hide();
        setTimeout(function () {
            $toggleCarrelloOpen.fadeIn(300).addClass("container-btn-carrello-two--open");
        },600);
        $("#PageBackDrop").show();
    });

    // CHIUSURA CARRELLO
    $toggleCarrelloOpen.click(function () {
        $('#carrelloSidebar').animate({
            right: "-179px"
        },600).toggleClass("carrello_open");
        $(this).hide().removeClass("container-btn-carrello-two--open");
        setTimeout(function () {
            $('#toggleCarrello').fadeIn(300);
        },600);
        $("#PageBackDrop").hide();
    });

    /* chiude carrello sidebar con la X */
    $('.carrello-close-btn').click(function () {
        $('#carrelloSidebar').animate({
            right: "-179px"
        },600).toggleClass("carrello_open");
        $toggleCarrelloOpen.hide().removeClass("container-btn-carrello-two--open");
        setTimeout(function () {
            $('#toggleCarrello').fadeIn(300);
        },600);
        $("#PageBackDrop").hide();

        //$('#carrelloSidebar').toggleClass("carrello_open");
        //$('#toggleCarrelloOpen').hide().removeClass("container-btn-carrello-two--open");;
        //$('#toggleCarrello').show().removeClass("container-btn-carrello--closed");
    });

    /* Carosusel premio GARANZIE desktop */
    if ($('.karte__carousel').length) {
        $('.karte__carousel').slick({
            infinite: false,
            dots: true,
            arrows: true,
            fade: false,
            adaptiveHeight: false,
            speed: 300,
            slidesToShow: 6,
            slidesToScroll: 6,
            //centerMode: true,
            //variableWidth: true,
            responsive: [
                {
                    breakpoint: 1580,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4,
                    }
                },
                {
                    breakpoint: 1180,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    }
                }
            ]
        });
    }
    /* Bottone personalizza */
    $('#FamigliaToMisura, #CasaToMisura').on('click', function (e) {
        e.preventDefault()
        $('#personalizzabile-tab').tab('show')
    });

    $('.karte__element').click(function () {
        var $this; //JSHINT
        $this = $(this);
        if (!$this.hasClass('disabled') && !$this.hasClass('required')) {
            $this.toggleClass('active');
        }
    });


});