$(document).ready(function() {
    if ($('.scrivici').length) {

        jQuery.validator.addMethod("codfiscale", function(value) {
            var regex = /[A-Z]{6}[\d]{2}[A-Z][\d]{2}[A-Z][\d]{3}[A-Z]/;
            return value. match(regex);
        }, "");

        $('#scriviciForm').validate({
            errorClass: "has-error",
            successClass: "has-success",
            ignore: '',
            rules: {
                // simple rule, converted to {required:true}
                'scrivici_name': 'required',
                'scrivici_surname': 'required',
                'scrivici_fiscalcode': 'required',
                'scrivici_email': 'required',
                'scrivici_telephone': 'required',
                'scrivici_domanda': 'required',
                'g-recaptcha-response': 'required',
                'scrivici_acceptPrivacy': 'required',
            },
            messages: {
                'scrivici_name': "",
                'aniscrivici_surnamemal_name': "",
                'scrivici_fiscalcode': "",
                'scrivici_email': "",
                'scrivici_telephone': "",
                'scrivici_domanda': "",
                'g-recaptcha-response': "",
                'scrivici_acceptPrivacy': "",
            },
            highlight: function (element, errorClass, successClass) {
                $(element).closest('.row.space-m-y-25px').removeClass(successClass).addClass(errorClass);
            },
            unhighlight: function (element, errorClass, successClass) {
                $(element).closest('.row.space-m-y-25px').removeClass(errorClass).addClass(successClass);
            },
            errorPlacement: function (error, element) {
                console.log(error);
            },
            submitHandler: function (form, event) {
                // event.preventDefault();
                form.submit();
            }
        });

    }
});